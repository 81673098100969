import React from 'react';

import Container from '@material-ui/core/Container';

const Tmp = () => (
  <main>
    <Container>
      <h1>Gccash.gg is out of service.</h1>
      <h2>In case of any questions please contact us via online chat.</h2>
    </Container>
  </main>
);

export default Tmp;
